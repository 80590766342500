import {Haulier} from '@hconnect/apiclient'
import {Typography} from '@hconnect/uikit'
import {
  Box,
  ButtonBase,
  InputAdornment,
  LinearProgress,
  TextField,
  TextFieldProps
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import {AxiosError} from 'axios'
import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useHaulierByNumber} from '../hooks/useHaulier'
import {GroupedRoleAssignment} from '../modules/ManageUsers.selectors'

const useStyles = makeStyles((theme) => ({
  chipsContainer: {
    margin: `0 -${theme.spacing(0.25)}px`
  },
  chip: {
    margin: `0 ${theme.spacing(0.25)}px ${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`
  },
  noValueLabel: {
    fontStyle: 'italic',
    textAlign: 'center'
  }
}))

interface Props {
  hauliers: Haulier[]
  groupedRoleAssignment: GroupedRoleAssignment
  onChangeHaulierIds: (haulierIds: string[]) => void
  dataScopeName: string
}

export const RoleHaulierLookupTextField: FunctionComponent<
  Props & Omit<TextFieldProps, 'onChange'>
  // eslint-disable-next-line complexity
> = ({
  onChangeHaulierIds,
  hauliers,
  helperText,
  groupedRoleAssignment,
  dataScopeName,
  ...props
}) => {
  const classes = useStyles()
  const {t} = useTranslation()

  const [textFieldValue, setTextFieldValue] = useState<string>('')
  
  const haulierQueryInfo = useHaulierByNumber(
    textFieldValue,
    false
  )

  const newHauliers = haulierQueryInfo.data
  const errorMessage = (haulierQueryInfo.error as AxiosError)?.message

  const noResultError =
    newHauliers?.length === 0 ? t(`roleAssignment.noLookupResult.${dataScopeName}`) : ''

  const handleLookup = async () => haulierQueryInfo.refetch()

  useEffect(() => {
    if (newHauliers && newHauliers.length > 0) {
      onChangeHaulierIds(newHauliers.map((haulier) => haulier.haulierId))
      haulierQueryInfo.remove()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newHauliers])

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Enter') {
      return
    }

    event.preventDefault()
    void handleLookup()
  }

  const disabled = props.disabled

  return (
    <>
      <TextField
        {...props}
        label={t(`roleAssignment.dataScope.${dataScopeName}`)}
        variant="standard"
        value={disabled ? '' : textFieldValue}
        onChange={(event) => setTextFieldValue(event.target.value)}
        onKeyDown={handleKeyDown}
        error={!!helperText || haulierQueryInfo.isError || !!noResultError}
        helperText={errorMessage || helperText || noResultError}
        type="search"
        disabled={disabled}
        data-test-id="haulier-number-lookup"
        InputProps={{
          readOnly: haulierQueryInfo.isInitialLoading,
          disabled,
          endAdornment:
            textFieldValue.length > 0 && !disabled ? (
              <InputAdornment position="end" variant="outlined">
                <ButtonBase
                  onClick={() => {
                    void handleLookup()
                  }}
                  data-test-id="button-haulier-lookup"
                >
                  <AddIcon />
                </ButtonBase>
              </InputAdornment>
            ) : undefined
        }}
      />
      {haulierQueryInfo.isInitialLoading && (
        <Box mb={1}>
          <LinearProgress variant="indeterminate" />
        </Box>
      )}
      {!haulierQueryInfo.isInitialLoading && hauliers.length === 0 && (
        <Typography
          className={classes.noValueLabel}
          component="div"
          variant="caption"
          color="secondary"
        >
          {t(`roleAssignment.noValue.${dataScopeName}`)}
        </Typography>
      )}
    </>
  )
}
